export enum AppRouting {
  HOME = 'home',
  LOGIN = 'login',
  DEBUG = 'debug',
  SCANTAG = 'scan-tag',
  COMPLETEORDER = 'complete-orders',
  STORAGELIST = 'storage-list',
  DELIVERYORDER = 'delivery-order',
  SEARCH = 'search',
  CONTROL = 'control',
}
