import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Result from '@zxing/library/src/core/Result';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

import { AuthService } from '../../../lib/lib-ngx/web-services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'lm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  public showLoginForm = false;
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  public allowedFormats = [BarcodeFormat.QR_CODE];
  public showCamera = false;
  public desiredDevice: MediaDeviceInfo = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.showLoginForm = params.form === String(true) || !environment.production;
    });

    this.buildForm();
  }

  camerasFoundHandler(deviceList: MediaDeviceInfo[]) {
    this.desiredDevice = deviceList[0];
  }

  scanCompleteHandler(result: Result) {
    this.showCamera = false;
    this.authService.loginAPIKey({apiKey: JSON.parse(result.getText()).apiKey})
      .subscribe((data: any) => {
        if (data.hasOwnProperty('token')) {
          this.authService.setToken(data.token);
          this.router.navigate(['/home']);
          return;
        }

        this.showCamera = true;
      }, (e) => {
        this.showCamera = true;
      });
  }

  buildForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    });
  }

  login() {
    this.authService.login({email: this.loginForm.value.email, password: this.loginForm.value.password})
      .subscribe((data: any) => {
        if (data.hasOwnProperty('token')) {
          this.authService.setToken(data.token);
          this.router.navigate(['/']);
        }
      });
  }
}
