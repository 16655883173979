import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';
import { PrintService } from '../../services/print.service';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'lm-re-print',
  templateUrl: './re-print.component.html',
  styleUrls: ['./re-print.component.scss']
})
export class RePrintComponent implements OnInit {
  public idTransportSheetFormControl = new FormControl(null, Validators.required);

  constructor(
    private modalService: NgbModal,
    public apiLavandierService: ApiLavandierService,
    private errorService: ErrorService,
    private printService: PrintService,
  ) {
  }

  ngOnInit() {
  }

  reset() {
    this.idTransportSheetFormControl.reset();
  }

  onRePrint() {
    this.apiLavandierService.getTransportSheetIdRePrint(this.idTransportSheetFormControl.value, {})
      .subscribe(async data => {
        if (!this.errorService.manageError(data)) {
          await this.printService.print(data.pdfBase64).toPromise();
          await this.openPrintModal('Etiquette transport');
          this.reset();
        }
      });
  }

  async openPrintModal(modalText: string) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
      size: 'xl' as 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    confirmationModal.componentInstance.confirmationDesc = modalText;
    confirmationModal.componentInstance.validateButtonText = 'Ok';
    await confirmationModal.result;
  }
}
