import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { AppRouting } from '../../app-routing.module.type';

@Component({
  selector: 'lm-storage-list',
  templateUrl: './storage-list.component.html',
  styleUrls: ['./storage-list.component.scss']
})
export class StorageListComponent implements OnInit {
  public AppRouting = AppRouting;

  public filterForm: FormGroup;
  public storageList = [];

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private progressBagConfig: NgbProgressbarConfig,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.loadStorageList();
    this.progressBagConfig.max = 100;
    this.progressBagConfig.height = '37px';
  }

  buildForm() {
    this.filterForm = this.fb.group({
      orderId: '',
    });
  }

  loadStorageList() {
    this.apiLavandierService.getStorageUsedList()
      .subscribe((storageList: any[]) => {
        this.storageList = storageList;
      });
  }
}
