import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IOrderDetails } from '../../../lib/lib-shared/models/Order';
import { LightState } from '../../../lib/lib-shared/types/LightState';
import { ScanStorageModalComponent } from '../modals/scan-storage-modal/scan-storage-modal.component';
import { LightService } from '../../services/light.service';
import { ITrackingSheet } from '../../../lib/lib-shared/models/TrackingSheet';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { IUserType } from '../../../lib/lib-shared/models/UserType';
import { PrintService } from '../../services/print.service';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';

@Component({
  selector: 'lm-complete-orders',
  templateUrl: './complete-orders.component.html',
  styleUrls: ['./complete-orders.component.scss']
})
export class CompleteOrdersComponent implements OnInit {
  public storageMap: Map<number, any> = new Map();
  public userTypeMap: Map<number, IUserType> = new Map();
  public orderList: IOrderDetails[] = [];
  public nbTrackingSheetByOrderIdMap: Map<number, number> = new Map();

  constructor(
    private apiLavandierService: ApiLavandierService,
    private lightService: LightService,
    private modalService: NgbModal,
    private printService: PrintService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.apiLavandierService.getStorageList()
      .subscribe((storageList: any[]) => {
        this.storageMap = ListToMap.convert(storageList);
      });
    this.apiLavandierService.getUserTypeList()
      .subscribe((userTypeList: any[]) => {
        this.userTypeMap = ListToMap.convert(userTypeList);
      });

    this.apiLavandierService.getOrderReadyToShip()
      .subscribe((orderList: IOrderDetails[]) => {
        this.orderList = orderList;
        this.nbTrackingSheetByOrderIdMap = new Map();
        for (const order of this.orderList) {
          for (const orderBag of order.orderBags) {
            for (const orderArticle of orderBag.orderArticles) {
              for (const orderArticleTracking of orderArticle.orderArticleTrackingList) {
                let oldCount = 0;
                if (this.nbTrackingSheetByOrderIdMap.has(order.id)) {
                  oldCount = this.nbTrackingSheetByOrderIdMap.get(order.id);
                }
                this.nbTrackingSheetByOrderIdMap.set(order.id, oldCount + 1);
              }
            }
          }
        }
      });
  }

  async onTakeOver(orderId?: number) {
    let order = this.orderList[0];
    if (orderId) {
      order = this.orderList.find(o => o.id === orderId);
    }

    const trackingSheetListByStorageIdMap: Map<number, ITrackingSheet[]> = new Map();
    for (const orderBag of order.orderBags) {
      for (const orderArticle of orderBag.orderArticles) {
        for (const orderArticleTracking of orderArticle.orderArticleTrackingList) {
          let trackingSheetList = [];
          if (trackingSheetListByStorageIdMap.has(orderArticleTracking.storageId)) {
            trackingSheetList = trackingSheetListByStorageIdMap.get(orderArticleTracking.storageId);
          }

          if (!trackingSheetList.some(ts => ts.id === orderArticleTracking.trackingSheet.id)) {
            trackingSheetList.push(orderArticleTracking.trackingSheet);
            trackingSheetListByStorageIdMap.set(orderArticleTracking.storageId, trackingSheetList);
          }
        }
      }
    }
    for (const [storageId, trackingSheetList] of Array.from(trackingSheetListByStorageIdMap.entries())) {
      for (const trackingSheet of trackingSheetList) {
        await this.openStorageModal({storageId: storageId, order: order, trackingSheet: trackingSheet});
      }
    }

    this.loadData();
  }

  async openStorageModal(params: { storageId: number, order: IOrderDetails, trackingSheet: ITrackingSheet }) {
    const storage = this.storageMap.get(params.storageId);
    this.lightService.setState(storage.lightId, LightState.ON).subscribe();

    const data: any = await this.apiLavandierService.putTrackingSheetPTLTook(params.trackingSheet.id).toPromise();
    if (!this.errorService.manageError(data)) {
      const scanStorageModal = this.modalService.open(ScanStorageModalComponent, {
        size: 'xl' as 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true
      });
      scanStorageModal.componentInstance.ref = storage.ref;
      scanStorageModal.componentInstance.userType = this.userTypeMap.get(params.order.user.userTypeId);
      scanStorageModal.componentInstance.user = params.order.user;
      scanStorageModal.componentInstance.order = params.order;

      await scanStorageModal.result;

      this.lightService.setState(storage.lightId, LightState.OFF).subscribe();
    }
  }
}
