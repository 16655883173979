import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { FreeStorageModalComponent } from '../modals/free-storage-modal/free-storage-modal.component';
import { DebugService } from '../../services/debug.service';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';

@Component({
  selector: 'lm-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public orderIdFormControl = new FormControl(null, Validators.required);
  public orderPinFormControl = new FormControl(null, Validators.required);
  public orderArticleTrackingList = [];
  public orderDetails = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public debugService: DebugService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.orderIdFormControl.setValue(params.id);
        this.searchOrder();
      }
    });
  }

  searchOrder() {
    this.orderDetails = null;
    this.orderArticleTrackingList = [];
    this.apiLavandierService.getOrderIdOrderArticleTrackingList(this.orderIdFormControl.value)
      .subscribe((orderArticleTrackingList: any[]) => {
        this.orderArticleTrackingList = orderArticleTrackingList;
        if (orderArticleTrackingList.length) {
          this.orderDetails = orderArticleTrackingList[0].orderArticle.orderBag.order;
        }
      });
  }

  searchOrderNumeroPin() {
    this.orderDetails = null;
    this.orderArticleTrackingList = [];
    this.apiLavandierService.getOrderByPin(this.orderPinFormControl.value)
      .subscribe((orderArticleTrackingList: any[]) => {
        this.orderArticleTrackingList = orderArticleTrackingList;
        if (orderArticleTrackingList.length) {
          this.orderDetails = orderArticleTrackingList[0].orderArticle.orderBag.order;
        }
      });
  }

  openTrackingSheetModal(event) {
    event.cellElement.blur(); // Method called to prevent ExpressionChangedAfterItHasBeenCheckedError
    const freeStorageModalComponent = this.modalService.open(FreeStorageModalComponent, {
      size: 'xl' as 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    freeStorageModalComponent.componentInstance.pdfUrlObservable = this.apiLavandierService.getDocTrackingSheetType(event.row.trackingSheetId, {type: 'pdf'});
  }

  untidied(orderArticleTracking) {
    this.apiLavandierService.putOrderArticleTrackingIdUnTidied(orderArticleTracking.id)
      .subscribe(data => {
        if (!this.errorService.manageError(data)) {
          this.searchOrder();
        }
      });
  }
}
