import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiLavandierService } from '../../lib/lib-ngx/web-services/api-lavandier.service';
import { LightState } from '../../lib/lib-shared/types/LightState';

@Injectable({
  providedIn: 'root'
})
export class LightService {
  private bridgeMap = new Map();
  private lightMap = new Map();

  constructor(
    private http: HttpClient,
    private apiLavandierService: ApiLavandierService,
  ) {
    this.reset();
  }

  public reset() {
    this.bridgeMap = new Map();
    this.lightMap = new Map();
  }

  public init(all = false): Observable<boolean> {
    this.reset();
    return forkJoin([this.apiLavandierService.getLightBridgeList(), this.apiLavandierService.getLightList({})])
      .pipe(
        map(([bridgeList, lightList]: [any[], any[]]) => {
          bridgeList.forEach(bridge => {
            this.bridgeMap.set(bridge.id, bridge);
          });
          lightList.forEach(light => {
            this.lightMap.set(light.id, light);
          });
        }),
        mergeMap(() => {
          return this.isBridgeListPresent();
        }),
        /*mergeMap(isBridgeListPresent => {
          return this.restoreAllLightState()
            .pipe(
              map(() => {
                return isBridgeListPresent;
              })
            );
        })*/
      );
  }

  private isBridgeListPresent(): Observable<boolean> {
    return this.http.get('https://discovery.meethue.com/')
      .pipe(
        map((bridgeList: Object[]) => {
          const bridgeMap = new Map;

          bridgeList.forEach((bridge: any) => {
            bridgeMap.set(bridge.id, bridge);
          });

          return bridgeMap;
        }),
        map(bridgeLiveMap => {
          for (const id of Array.from(this.bridgeMap.keys())) {
            const bridge = bridgeLiveMap.get(this.bridgeMap.get(id).ref);
            if (bridge === undefined) {
              return false;
            }
            this.bridgeMap.get(id)['ip'] = bridge.internalipaddress;
          }
          return true;
        })
      );
  }

  public setState(lightId: number, state: LightState): Observable<any> {
    const stateData = {
      on: false,
      transitiontime: 0,
      bri: environment.bri,
      alert: 'none'
    };

    switch (state) {
      case LightState.ON:
        stateData.on = true;
        break;

      case LightState.ALERT:
        stateData.on = true;
        stateData.bri = 0;
        stateData.alert = 'lselect';
        break;

      case LightState.OFF:
      default:
        stateData.on = false;
        stateData.bri = 0;
        break;
    }
    const light = this.lightMap.get(lightId);
    const bridge = this.bridgeMap.get(light.bridgeId);

    return this.http.put('https://' + bridge.ip + '/api/' + bridge.user + '/lights/' + light.number + '/state', stateData)
      .pipe(
        mergeMap(() => {
          return this.apiLavandierService.putLight(light.id, {state: state});
        })
      );
  }

  public restoreAllLightState(): Observable<any> {
    const observableList = [];

    this.lightMap.forEach(light => {
      observableList.push(this.setState(light.id, light.state));
    });

    return forkJoin(observableList);
  }
}
