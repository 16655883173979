import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'lm-free-storage-modal',
  templateUrl: './free-storage-modal.component.html',
  styleUrls: ['./free-storage-modal.component.scss']
})
export class FreeStorageModalComponent implements OnInit {
  @ViewChild('orderPdfInput') public orderPdfInput: ElementRef;
  @ViewChild('pdf') public pdf: ElementRef;
  public storageData = null;
  public pdfUrlObservable: Observable<any>;
  public trackingSheetFormControl = new FormControl(null);
  public trackingSheet = null;
  public userTypeMap = new Map();

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    if (this.storageData !== null) {
      this.getStorageNumberInputFocus();
    }
    this.pdfUrlObservable.subscribe(
      (data: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          this.pdf.nativeElement.src = reader.result;
        };
      }
    );
  }

  checkTrackingSheet() {
    if (this.trackingSheetFormControl.value === this.trackingSheet.id) {
      this.activeModal.close();
    }
  }

  getStorageNumberInputFocus() {
    setTimeout(() => this.orderPdfInput.nativeElement.focus());
  }
}
