import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ApiPublicService } from '../../../lib/lib-ngx/web-services/api-public.service';
import { IKit } from '../../../lib/lib-shared/models/Kit';
import { PRICEMULTIPLIER } from '../../../lib/lib-shared/defines';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { PrintService } from '../../services/print.service';
import { OrderKitBatchScanModalComponent } from '../modals/order-kit-batch-scan-modal/order-kit-batch-scan-modal.component';
import { IOrderDetailsLIstOfKitBatchWaitingShipping, IOrderDetailsLIstOfKitWaitingShipping } from '../../../lib/lib-shared/models/Order';
import { TransportSheetModalComponent } from '../modals/transport-sheet-modal/transport-sheet-modal.component';

@Component({
  selector: 'lm-prepare-kit-batch',
  templateUrl: './prepare-kit-batch.component.html',
  styleUrls: ['./prepare-kit-batch.component.scss']
})
export class PrepareKitBatchComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  private kitList: IKit[];
  public orderKitBatchList: IOrderDetailsLIstOfKitBatchWaitingShipping[];
  public orderKitList: IOrderDetailsLIstOfKitWaitingShipping[];

  constructor(
    private apiLavandierService: ApiLavandierService,
    private apiPublicService: ApiPublicService,
    private errorService: ErrorService,
    private modalService: NgbModal,
    private printService: PrintService,
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    forkJoin([
      this.apiPublicService.getKit({}),
      this.apiLavandierService.getOrderKitBatch({}),
      this.apiLavandierService.getOrderKit({}),
    ])
      .subscribe(([kitList, orderKitBatchList, orderKitList]) => {
        this.kitList = kitList;
        this.orderKitBatchList = orderKitBatchList;
        this.orderKitList = orderKitList;
      });
  }

  onCreateKitBatch(defaultQty: number, othersQty: number) {
    const defaultKit = this.kitList.find(k => k.default);
    const otherKitList = this.kitList.filter(k => !k.default);

    const kitList: { kitId: number, quantity: number }[] = [{kitId: defaultKit.id, quantity: defaultQty * PRICEMULTIPLIER}];
    for (const kit of otherKitList) {
      kitList.push({kitId: kit.id, quantity: othersQty * PRICEMULTIPLIER});
    }

    this.apiLavandierService.postOrderKitBatch({kitList: kitList})
      .subscribe(async (data: any) => {
        if (!this.errorService.manageError(data)) {
          for (const transportSheetDataOrderKit of data.transportSheetDataOrderKitList) {
            for (const transportSheetData of transportSheetDataOrderKit.transportSheetDataList) {
              await this.printService.print(transportSheetData.pdfBase64).toPromise();
            }
            await this.openPrintModal(`${transportSheetDataOrderKit.transportSheetDataList.length} x sac ${transportSheetDataOrderKit.orderKit.name}`);
          }

          await this.printService.print(data.transportSheetData.pdfBase64).toPromise();
          await this.openPrintModal('Etiquette temporaire');
          this.loadData();
        }
      });
  }

  async openPrintModal(modalText: string) {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
      size: 'xl' as 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    confirmationModal.componentInstance.confirmationDesc = modalText;
    confirmationModal.componentInstance.validateButtonText = 'Ok';
    await confirmationModal.result;
  }

  async openTransportSheetModal(sticker: boolean) {
    const transportSheetModal = this.modalService.open(TransportSheetModalComponent, {
      size: 'xl' as 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    transportSheetModal.componentInstance.sticker = sticker;
    await transportSheetModal.result;
  }

  async onTakeOverKit(order: any) {
    this.apiLavandierService.putOrderIdKit(order.id, {})
      .subscribe(async data => {
        if (!this.errorService.manageError(data)) {
          for (const transportSheetDataOrderKit of data.transportSheetDataOrderKitList) {
            for (const transportSheetData of transportSheetDataOrderKit.transportSheetDataList) {
              await this.printService.print(transportSheetData.pdfBase64).toPromise();
              await this.printService.print(data.transportSheetData.pdfBase64).toPromise();
              await this.openPrintModal(`Etiquette ${transportSheetDataOrderKit.orderKit.name} et d'adressage`);
            }
          }
          this.loadData();
        }
      });
  }

  async onTakeOverKitBatch(order: IOrderDetailsLIstOfKitBatchWaitingShipping) {
    const orderKitBatchScanModalComponent = this.modalService.open(OrderKitBatchScanModalComponent, {
      size: 'xl' as 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    orderKitBatchScanModalComponent.componentInstance.order = order;
    const orderId = await orderKitBatchScanModalComponent.result;

    this.apiLavandierService.putOrderIdKitBatch(order.id, {orderId: orderId})
      .subscribe(async data => {
        if (!this.errorService.manageError(data)) {
          await this.printService.print(data.transportSheetData.pdfBase64).toPromise();
          await this.openTransportSheetModal(data.sticker);
          this.loadData();
        } else {
          this.onTakeOverKitBatch(order);
        }
      });
  }
}
