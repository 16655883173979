import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LightState } from '../../lib/lib-shared/types/LightState';

@Injectable({
  providedIn: 'root'
})
export class LightService {
  private bridgeMap = new Map();
  private lightMap = new Map();

  constructor() {
    this.reset();
  }

  public reset() {
    this.bridgeMap = new Map();
    this.lightMap = new Map();
  }

  public init(all = false): Observable<boolean> {
    return of(true);
  }

  public setState(lightId: number, state: LightState): Observable<any> {
    return of(null);
  }
}
