import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  private url = 'http://localhost:4242';

  constructor(
    private http: HttpClient,
  ) {
  }

  public print(pdfBase64: string) {
    return this.http.post(`${this.url}/print`, {pdfBase64: pdfBase64});
  }
}
